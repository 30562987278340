@font-face {
  font-family: 'Graphik Regular', 'Graphik';
  src: url('./Graphik_Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Semibold';
  src: url('./Graphik_Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}